import React from 'react';

export function NumericInput({ value, classes, disabled, onIncrement, onDecrement, onChange }) {
    return (
        <div className="flex justify-between align-center input-button input-button--outline-tall input-button--no-cursor">
            <button
                disabled={disabled}
                aria-label="Decrement"
                onClick={onDecrement}
                type="button"
                className="large link p-8 cursor"
                style={{ flex: '0 1 auto' }}>
                <span>-</span>
            </button>
            <input
                disabled={disabled}
                type="numeric"
                className={`large link center-text ${classes}`}
                style={{
                    flex: '1 1 auto',
                    width: '100%',
                    minWidth: '100px',
                    marginLeft: '1em',
                    marginRight: '1em',
                }}
                min="1"
                max="99"
                value={value}
                onChange={onChange}
            />
            <button
                disabled={disabled}
                aria-label="Increment"
                onClick={onIncrement}
                type="button"
                className="large link p-8 cursor"
                style={{ flex: '0 1 auto' }}>
                <span>+</span>
            </button>
        </div>
    );
}

export default NumericInput;
