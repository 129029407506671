import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useShop } from '@src/contexts/Shop';
import { formatPrice } from '@src/utils/Shop';

// components
import { NumericInput } from '@src/components/numericInput/NumericInput';
import Asset from '@src/components/asset/Asset';
import Loader from '@src/components/loader/Loader';
import RippleCanvas from '@src/components/rippleCanvas/RippleCanvas';
import { AddToCart } from './AddToCart';

export function Product({ data: entry }) {
    const { queryProduct } = useShop();
    const productId = entry.storefrontId; // encode id for storefront api

    const [isLoading, setIsLoading] = useState(true);

    const [product, setProduct] = useState({});
    const [variant, setVariant] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState('');

    const [subscriptionGroups, setSubscriptionGroups] = useState({});
    const [allocation, setAllocation] = useState({});
    const [subSelected, setSubSelected] = useState(false);

    const [currentImage, setCurrentImage] = useState(0);

    const handleOptionChange = (index, value) => {
        if (value === '') return;

        const selectedVariant = product.variants?.edges?.find(
            ({ node: { selectedOptions } }) =>
                selectedOptions[index].name === variant?.selectedOptions[index].name &&
                selectedOptions[index].value === value
        )?.node;

        if (selectedVariant) {
            setVariant({ ...selectedVariant });
            setAllocation({ ...selectedVariant.sellingPlanAllocations?.edges[0]?.node });
            setSubscriptionGroups({ ...selectedVariant.sellingPlanAllocations });
        }
    };

    const handleAllocationChange = value => {
        if (value === '') return;

        const selectedAllocation = subscriptionGroups?.edges?.find(
            ({ node: { sellingPlan } }) => sellingPlan.id === value
        )?.node;

        if (selectedAllocation) {
            setAllocation({ ...selectedAllocation });
        }
    };

    useEffect(() => {
        queryProduct(productId).then(fetchedProduct => {
            if (!fetchedProduct) return;
            setProduct(fetchedProduct);
            setVariant({ ...fetchedProduct.variants?.edges[0]?.node });

            setAllocation({
                ...fetchedProduct.variants?.edges[0]?.node.sellingPlanAllocations?.edges[0]?.node,
            });
            setSubscriptionGroups({
                ...fetchedProduct.variants?.edges[0]?.node.sellingPlanAllocations,
            });

            setIsLoading(false);
        });
    }, [productId]);

    useEffect(() => {
        if (subSelected && allocation.priceAdjustments[0].price) {
            setPrice(
                formatPrice(
                    allocation.priceAdjustments[0].price.currencyCode,
                    Math.max(1, allocation.priceAdjustments[0].price.amount * quantity)
                )
            );
        } else if (variant?.priceV2) {
            setPrice(
                formatPrice(
                    variant.priceV2.currencyCode,
                    Math.max(1, variant.priceV2.amount * quantity)
                )
            );
        }
    }, [variant, quantity, allocation, subSelected]);

    const images = entry.multipleImages;

    const productDetails = (
        <div className="flex wrap">
            {product && product.variants?.edges?.length > 0 && variant.selectedOptions?.length > 0
                ? product.options.map(({ id, name, values }, optionIndex) => (
                      <div className="product__detail m-42" key={[variant?.id, id]}>
                          <h3 className="h3">{name}</h3>
                          <div className="flex">
                              {values.map(value => (
                                  <div key={value} className="relative">
                                      <input
                                          type="radio"
                                          className="input__raindrop"
                                          id={id}
                                          name={name}
                                          value={value}
                                          checked={
                                              variant.selectedOptions[optionIndex].value === value
                                          }
                                          onChange={e => handleOptionChange(optionIndex, value)}
                                      />
                                      <label className="center-text" htmlFor={id}>
                                          <span
                                              style={{
                                                  position: 'relative',
                                                  left: '50%',
                                                  transform: 'translate3d(-50%, 0, 0)',
                                              }}>
                                              {value}
                                          </span>
                                      </label>
                                  </div>
                              ))}
                          </div>
                      </div>
                  ))
                : null}
            <div className="product__detail m-42">
                <h3 className="h3">Quantity</h3>
                <NumericInput
                    aria-label="Quantity"
                    onIncrement={() => setQuantity(q => Math.min(q + 1, 20))}
                    onDecrement={() => setQuantity(q => Math.max(1, q - 1))}
                    onChange={e => setQuantity(e.currentTarget.value)}
                    value={quantity}
                    min="1"
                    max="20"
                />
            </div>

            {subscriptionGroups.edges?.length > 0 ? (
                <div style={{ marginRight: 42 }} className="m-42">
                    <div className="m-8">
                        <input
                            type="radio"
                            className="input__radio"
                            name="subscription"
                            value="subscribe-single"
                            id="subscribe-single"
                            checked={!subSelected}
                            onChange={() => setSubSelected(false)}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="subscribe-single">Single purchase</label>
                    </div>
                    <div className={`${subSelected ? 'm-24' : ''}`}>
                        <input
                            type="radio"
                            className="input__radio"
                            name="subscription"
                            value="subscribe-subscription"
                            id="subscribe-subscription"
                            onChange={() => setSubSelected(true)}
                        />
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor="subscribe-subscription">Subscribe and save 10%</label>
                    </div>

                    {subscriptionGroups.edges?.length > 0 && subSelected ? (
                        <div className="dropdown-wrapper">
                            <select
                                className="input input__select m-24"
                                onChange={e => handleAllocationChange(e.target.value)}>
                                {subscriptionGroups.edges.map(({ node }) => (
                                    <option key={node.sellingPlan.id} value={node.sellingPlan.id}>
                                        {node.sellingPlan.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ) : null}
                </div>
            ) : null}

            <h2 className="product__detail h2" style={{ minWidth: '9ch' }}>
                {price}
            </h2>
            <div>
                <AddToCart
                    variantId={variant.id}
                    quantity={quantity}
                    available={variant.available}
                    sellingPlanId={subSelected ? allocation.sellingPlan?.id : null}
                />
            </div>
        </div>
    );

    const imageEl = (
        <>
            {images[currentImage] && (
                <TransitionGroup className="product-transition-group relative">
                    <CSSTransition
                        key={`image-${currentImage}`}
                        classNames="product-transition"
                        unmountOnExit
                        timeout={250}>
                        <div className="product__image relative flex justify-center align-center m-auto m-64">
                            <Asset
                                square
                                data={images[currentImage].imageFile}
                                title={images[currentImage].title && images[currentImage].title}
                            />
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            )}
            {images.length > 1 ? (
                <div className="product__images flex" style={{ gap: '12px' }}>
                    {images.map((image, i) => (
                        <button
                            className="product__button"
                            style={{ flex: '1 0 0' }}
                            key={image.title}
                            type="button"
                            onClick={() => setCurrentImage(i)}>
                            <Asset contain data={image.imageFile} title={image.title} />
                        </button>
                    ))}
                </div>
            ) : null}
        </>
    );

    return (
        <div className="product grid grid--1-2 m-240">
            <div className="m-auto">
                {entry.rippleAsset[0] ? (
                    <RippleCanvas product asset={entry.rippleAsset[0]} />
                ) : (
                    imageEl
                )}
            </div>

            <div>
                <h1 className="h1">{entry.title}</h1>
                {entry.richTextStandard ? (
                    <div
                        className="rich-text m-42"
                        dangerouslySetInnerHTML={{ __html: entry.richTextStandard }}
                    />
                ) : null}

                {isLoading ? (
                    <div className="relative flex full justify-center">
                        <Loader />
                    </div>
                ) : (
                    productDetails
                )}
            </div>
        </div>
    );
}

export default Product;
