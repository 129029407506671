import React from 'react';
import { useShop } from '@src/contexts/Shop';
import Button from '@src/components/button/Button';

export function AddToCart({ variantId, quantity, available, sellingPlanId = null }) {
    const { addItem, loading } = useShop();

    function addToCart(e) {
        e.preventDefault();
        addItem(variantId, quantity, sellingPlanId);
    }

    return (
        <Button
            onClick={addToCart}
            disabled={!available || loading}
            large
            copy={available ? 'Add to Cart' : 'Out of Stock'}
        />
    );
}

export default AddToCart;
