import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

// components
import { Product } from '@src/components/product/Product';
import Seo from '@src/components/seo/SEO';

// styles
import './product.scss';

// utils
import { renderSwitch } from '../utils/Render';

const ProductPage = ({ data: { page }, pageContext: { storefrontId }, location }) => {
    const entry = page;
    const content = entry.contentMatrix;
    const { seo } = entry;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }

    entry.storefrontId = storefrontId;

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
            />
            {location.href !== undefined ? (
                <Helmet>
                    <script type="application/ld+json">
                        {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Product",
                            "url": "${location.href}",
                            "name": "${seo.title}",
                            "description": "${seo.description}",
                            "image": "${seo.social.facebook.image?.url}",
                            "brand": {
                                "@type": "Thing",
                                "name": "Heart Water"
                            }
                        }
                    `}
                    </script>
                </Helmet>
            ) : null}
            <div className="spacing--small">
                <Product data={entry} />
                {content.map((block, i) => renderSwitch(block, i))}
            </div>
        </>
    );
};

export const query = graphql`
    query ($id: IDQueryOperatorInput) {
        page: craftShopProductEntry(remoteId: $id) {
            id: remoteId
            title
            uri
            ... on Craft_shop_product_Entry {
                shopifyProduct
                richTextStandard
                multipleImages {
                    url
                    title
                    ... on Craft_images_Asset {
                        id: remoteId
                        url
                        title
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }
                        }
                    }
                }
                rippleAsset {
                    url
                    title
                    ... on Craft_images_Asset {
                        id: remoteId
                        url
                        title
                        imageFile {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED)
                            }
                        }
                    }
                }
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                    }
                }
                dateUpdated
                contentMatrix {
                    ...RichTextFragment
                    ...ProductsBlockFragment
                    ...TextGridBlockFragment
                    ...CalloutCtaBlockFragment
                    ...LargeCalloutBlockFragment
                    ...RainyDaysCalloutFragment
                    ...SingleMediaFragment
                    ...TextAndImageGridBlockFragment
                    ...ThumbnailImageGridFragment
                    ...TwoUpImageBlockFragment
                    ...ImageCarouselFragment
                    ...TextAndImageCtaBlockFragment
                    ...NewsletterSignupFragment
                    ...TextImageBlockFragment
                    ...PressBlockFragment
                    ...BannerBlockFragment
                    ...TimelineBlockFragment
                    ...FigureLinkGridFragment
                    ...ContactFormFragment
                    ...CloudGraphic
                    ...RainGraphic
                    ...StoremapperEmbed
                }
            }
        }
    }
`;

export default ProductPage;
